.logo {
  width: 150px;
  float: left;
  font-size: 18px;
  height: 31px;
}

.logo-color {
  color: white;
}

.logo-margin {
  margin-right: 6px;
}

.content-padding {
  padding: 50px 70px;
}

.copyright {
  text-align: center;
}

.ant-menu-submenu {
  margin-left: auto;
}
